import React, { createContext, useContext, useState } from 'react';
import { useAdminProfileQuery } from 'src/graphql/queries/GetAdmin';
import { AdminProfile_admin } from 'src/graphql/queries/__generated__/AdminProfile';
import { AbilityContext, updateAbility } from 'src/contexts/AbilityContext';
import { authContext } from 'src/contexts/AuthContext';

interface AdminProfileContextProps {
  profile: AdminProfile_admin | null;
  isLoading: boolean;
}

export const AdminProfileContext = createContext<AdminProfileContextProps>({
  profile: null,
  isLoading: true,
});

const AdminProfileContextProvider: React.FC = ({ children }) => {
  const ability = useContext(AbilityContext);
  const auth = useContext(authContext);
  const [adminProfile, setAdminProfile] = useState<AdminProfile_admin | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useAdminProfileQuery({
    onCompleted: (adminData) => {
      const { admin } = adminData || {};
      // After fetching the admin profile, update the context:
      setAdminProfile(admin || null);

      // update permissions
      if (admin?.permissions) {
        updateAbility(ability, admin.permissions);
      }

      setIsLoading(false);
    },
    onError: () => {
      auth.removeAuthToken();
    },
  });

  return (
    <AdminProfileContext.Provider value={{ profile: adminProfile, isLoading }}>
      {children}
    </AdminProfileContext.Provider>
  );
};

export default AdminProfileContextProvider;
