import { WatchQueryFetchPolicy } from '@apollo/client';
import { Switch } from '@headlessui/react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ApplyForTenderPopover from 'src/components/ApplyForTenderPopover';
import BadgeWrapper from 'src/components/BadgeWrapper';
import Copy from 'src/components/Icons/Copy';
import Human from 'src/components/Icons/Human';
import HumanPrevious from 'src/components/Icons/HumanPrevious';
import Info from 'src/components/Icons/info';
import RequestTenderPopover from 'src/components/RequestTenderPopover';
import SpacedDot from 'src/components/SpacedDot';
import StaffingList from 'src/components/StaffingList';
import { Can } from 'src/contexts/AbilityContext';
import { StaffingSelectionContext } from 'src/contexts/StaffingsSelectionContext';
import { useCancelShiftRequestMutation } from 'src/graphql/mutations/CancelShiftRequest';
import { useToggleShiftVisibilityInOpenJobsMutation } from 'src/graphql/mutations/ToggleShiftVisibilityInOpenJobs';
import { useLazyApprovedJobApplicationsPaginatedQuery } from 'src/graphql/queries/ApprovedJobApplicationsPaginated';
import { useLazyPendingJobApplicationsPaginatedQuery } from 'src/graphql/queries/PendingJobApplicationsPaginated';
import { useLazyRemovedJobApplicationsPaginatedQuery } from 'src/graphql/queries/RemovedJobApplicationsPaginated';
import SHIFT_REQUESTS, {
  useLazyShiftRequestsQuery,
} from 'src/graphql/queries/ShiftRequests';
import {
  GetShifts_shifts_edges_node,
  GetShifts_shifts_edges_node_job_client_tags,
  GetShifts_shifts_edges_node_job_venue,
} from 'src/graphql/queries/__generated__/GetShifts';
import { subscribeToMoreJobApplicationsOptions } from 'src/graphql/subscriptions/JobApplicationUpdated';
import {
  STAFFINGS_DEFAULT_PAGE_SIZE,
  RETOOL_STAFFING_AUTOMATION_APP_URL,
} from 'src/utils/constants';
import {
  OrderByDirectionEnum,
  ShiftStatus,
  ShiftWorkerClassification,
  TipType,
} from 'src/__generated__/globalTypes';
import { ApprovedStaffingSelectionContext } from 'src/contexts/ApprovedStaffingsSelectionContext';
import { NotesModal } from '../views/Staffings/NotesModal';
import ChevronDown from './Icons/ChevronDown';
import ChevronUp from './Icons/ChevronUp';
import Note from './Icons/Note';
import RequestsList from './StaffingList/RequestsList';
import { ColumnName } from './StaffingList/StaffingList';
import Refresh from './Icons/Refresh';
import { useUpdateShiftStaffingReliabilityPredictionMutation } from '../graphql/mutations/UpdateShiftStaffingReliabilityPrediction';
import Message from './Icons/Message';
import ShareIcon from './Icons/Share';

const DATE_FORMAT = 'MMM d hh:mmaaa zzz';
const DATE_REL_FORMAT = 'MMM d hh:mmaaa';
const moneyFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
}).format;
const MAX_JOB_NAME_LENGTH = 28;

type ListOptions = {
  sortColumn: ColumnName;
  sortDirection: OrderByDirectionEnum;
  page: number;
};

const PositionRow: React.FC<{
  shift: GetShifts_shifts_edges_node;
  isFlexpoolShift: boolean;
  isCancelled: boolean;
  organizationName: string;
  unpaidBreakMinutes?: number;
  onToggle?: () => Promise<void> | void;
  isExpanded: boolean;
  isNowsta2: boolean;
}> = ({
  shift,
  isFlexpoolShift,
  isCancelled,
  organizationName,
  unpaidBreakMinutes,
  onToggle,
  isExpanded,
  isNowsta2,
}) => {
  const positionName = shift.position.name;
  const {
    id: shiftId,
    staffedSlots: staffedCount,
    quantity: totalSlotsCount,
    hiddenFromOpenShifts: isShiftHiddenFromOpenJobs,
    unreadNotes,
    notes,
  } = shift;
  const isW2 = shift.workerClassification === ShiftWorkerClassification.WC_W2;

  const flags = useFlags();

  const hasUnreadNotes = unreadNotes > 0;
  const hasNotes = notes && notes?.length > 0;

  const [toggleShiftVisibilityInOpenJobs, { loading }, ,] =
    useToggleShiftVisibilityInOpenJobsMutation({
      variables: { shiftId, isHidden: isShiftHiddenFromOpenJobs },
    });

  const isOverStaffed = staffedCount > totalSlotsCount;

  const renderData = {
    selectionBg:
      flags.canceledOrdersFilter && isCancelled
        ? 'selection:bg-status-destructive-light'
        : isNowsta2
        ? 'selection:bg-brand-nowsta2'
        : isFlexpoolShift
        ? 'selection:bg-brand-nowsta'
        : 'selection:bg-brand-50',
    backgroundClasses:
      flags.canceledOrdersFilter && isCancelled
        ? 'bg-status-destructive'
        : isNowsta2
        ? 'bg-brand-nowsta2'
        : isFlexpoolShift
        ? 'bg-brand-nowsta'
        : 'bg-brand-tend',
  };

  const [notesModalOpen, setNotesModalOpen] = useState(false);

  const notesButtonRef = useRef<HTMLButtonElement>(null);

  const handleOpenNotesModal = useCallback((): void => {
    setNotesModalOpen(true);
  }, [setNotesModalOpen]);

  const handleCloseNotesModal = useCallback((): void => {
    notesButtonRef.current?.focus();
    setNotesModalOpen(false);
  }, [setNotesModalOpen]);

  return (
    <div
      className={`bg-background-surface border-support-line flex gap-4 border-b ${renderData.selectionBg}`}
    >
      <NotesModal
        open={notesModalOpen}
        onClose={handleCloseNotesModal}
        shift={shift}
        organizationName={organizationName}
        unpaidBreakMinutes={unpaidBreakMinutes}
      />

      <div className="text-preset-4 text-ink-dark inline-flex flex-1 font-medium">
        {isW2 && (
          <span className="text-ink-clear bg-ink-dark inline-flex items-center px-2 font-bold">
            W-2
          </span>
        )}

        <div className="inline-flex flex-1 items-center px-4 py-5">
          <span
            className={`${renderData.backgroundClasses} text-preset-6 text-ink-clear mr-2 flex items-center rounded-full px-2 py-1`}
            title={
              isNowsta2
                ? 'from a Nowsta 2 Booking'
                : isFlexpoolShift
                ? 'from a Flexpool Booking'
                : 'from a Tend Booking'
            }
          >
            <Human className="mr-1 h-5 w-5" />
            {isOverStaffed ? totalSlotsCount : staffedCount}/{totalSlotsCount}
          </span>
          {isOverStaffed && (
            <span
              className="text-preset-6 text-status-destructive bg-status-destructive-light mr-2 flex rounded-full px-2 py-1"
              data-cy="overstaffed-icon"
            >
              <HumanPrevious className="mr-1 h-5 w-5" />
              {staffedCount - totalSlotsCount}
            </span>
          )}
          <span className="capitalize">{positionName}</span>
          {organizationName && (
            <div className="text-ink-not-as-dark ml-2">
              <SpacedDot />
              <span className="text-ink-dark capitalize">
                {organizationName}
              </span>
              <SpacedDot />
            </div>
          )}
          <span
            className="bg-background-app text-preset-7 text-ink-not-as-dark ml-2 cursor-pointer rounded
            py-1 px-1 font-medium opacity-100 hover:opacity-80 active:opacity-50"
            onClick={async (ev) => {
              await navigator.clipboard.writeText(shiftId);
            }}
          >
            <Copy className="mr-2 inline" height="16" />

            <span title={shiftId}>Shift ID</span>
          </span>

          <span
            className="bg-background-app text-preset-7 text-ink-not-as-dark ml-2 cursor-pointer rounded
            py-1 px-1 font-medium opacity-100 hover:opacity-80 active:opacity-50"
            onClick={async (ev) => {
              await navigator.clipboard.writeText(
                `https://hiretend.com/job/${shiftId}`,
              );
            }}
          >
            <Copy className="mr-2 inline" height="16" />

            <span title={`https://hiretend.com/job/${shiftId}`}>
              Shift Link ID
            </span>
          </span>

          <a
            className="bg-background-app text-preset-7 text-ink-not-as-dark ml-2 cursor-pointer rounded py-1 px-1 font-medium opacity-100 hover:opacity-80 active:opacity-50"
            href={`https://app.hiretend.com/last_minute_call_outs/?shift_id=${shiftId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Message className="mr-2 inline" height="16" />

            <span>Last Minute Requests</span>
          </a>

          <a
            className="bg-background-app text-preset-7 text-ink-not-as-dark ml-2 cursor-pointer rounded py-1 px-1 font-medium opacity-100 hover:opacity-80 active:opacity-50"
            href={`${RETOOL_STAFFING_AUTOMATION_APP_URL}#shiftId=${shiftId}`}
            target="_blank"
            rel="noreferrer"
          >
            <ShareIcon className="mr-2 inline h-4 w-3 pb-0.5" />

            <span>Automation</span>
          </a>

          {flags.canceledOrdersFilter && isCancelled && (
            <span className="bg-status-destructive text-preset-6 ml-2 rounded-full px-2  py-1 font-bold text-white">
              Canceled
            </span>
          )}
        </div>
        <Can I="update" a="Job">
          <div className="flex items-center">
            <span className="text-preset-6 text-ink-not-as-dark mr-2 font-normal">
              Show in Open Jobs
            </span>
            <Switch
              checked={!isShiftHiddenFromOpenJobs}
              disabled={loading}
              onChange={() => {
                toggleShiftVisibilityInOpenJobs();
              }}
              className={`${
                isShiftHiddenFromOpenJobs ? 'bg-background-app' : 'bg-brand-50'
              } border-primary focus:ring-primary relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2`}
            >
              <span className="sr-only">Show in Open Jobs</span>
              <span
                aria-hidden="true"
                className={`${
                  isShiftHiddenFromOpenJobs ? 'translate-x-0' : 'translate-x-5'
                } ring-primary pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-2 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
        </Can>
      </div>

      <button
        className="text-primary flex cursor-pointer items-center self-center"
        onClick={handleOpenNotesModal}
        ref={notesButtonRef}
        type="button"
      >
        <BadgeWrapper
          badge={unreadNotes || notes?.length || null}
          badgeClasses={`min-w-[16px] ${
            !hasUnreadNotes && hasNotes && '!bg-gray-500'
          }`}
        >
          <Note
            className="text-ink-not-as-dark hover:text-primary h-[22px] w-5"
            data-testid="shift-notes-icon"
          />
        </BadgeWrapper>
      </button>

      <div
        className={
          ' border-support-line-darker flex cursor-pointer items-center py-3 pr-4'
        }
        onClick={onToggle}
      >
        <div className="border-support-line-darker bg-support-line text-ink-not-as-dark text-preset-7 rounded border p-1 text-center">
          {isExpanded ? (
            <ChevronUp className="h-3 w-3" />
          ) : (
            <ChevronDown className="h-3 w-3" />
          )}
        </div>
      </div>
    </div>
  );
};

const JobRow: React.FC<{
  shiftId: string;
  jobName: string;
  jobId: string;
  startDate: string;
  endDate: string;
  totalShiftHours: number;
  unpaidBreakMinutes?: number;
  hourlyPayout: number;
  tipType?: TipType | null;
  tipAmount?: number | null;
  tipLabel?: string | null;
  venue?: GetShifts_shifts_edges_node_job_venue | null;
  reliabilityPrediction?: number | null;
  reliabilityPredictionTimestamp?: Date | null;
}> = ({
  shiftId,
  jobName,
  jobId,
  startDate,
  endDate,
  totalShiftHours,
  unpaidBreakMinutes,
  hourlyPayout,
  tipType = TipType.NO_TIP,
  tipAmount,
  tipLabel,
  venue,
  reliabilityPrediction,
  reliabilityPredictionTimestamp,
}) => {
  const { name: regionName } = venue?.region ?? {};
  const { timezone, city, state } = venue?.address ?? {};
  const startDateUTC = new Date(startDate);
  const zonedStartDate = timezone
    ? utcToZonedTime(startDateUTC, timezone)
    : startDateUTC;
  const formattedStartDate = format(zonedStartDate, DATE_FORMAT, {
    timeZone: timezone,
  });
  const endDateUTC = new Date(endDate);
  const zonedEndDate = timezone
    ? utcToZonedTime(endDateUTC, timezone)
    : endDateUTC;
  const formattedEndDate = format(zonedEndDate, DATE_FORMAT, {
    timeZone: timezone,
  });
  const totalHours = Math.floor(totalShiftHours);
  const remainingMinutes = Math.round((totalShiftHours * 60) % 60);

  const [updateReliabilityPrediction, { loading }, ,] =
    useUpdateShiftStaffingReliabilityPredictionMutation({
      variables: { id: shiftId },
      refetchQueries: [''],
    });

  const [updatedReliabilityPrediction, setUpdatedReliabilityPrediction] =
    useState(reliabilityPrediction);
  const [
    updatedReliabilityPredictionTimestamp,
    setUpdatedReliabilityPredictionTimestamp,
  ] = useState<string>(
    reliabilityPredictionTimestamp
      ? format(new Date(reliabilityPredictionTimestamp), DATE_REL_FORMAT)
      : '',
  );

  const handleUpdateReliabilityPrediction = async () => {
    const updatedResult = (await updateReliabilityPrediction()).data
      ?.updateShiftReliabilityPrediction;
    if (updatedResult) {
      setUpdatedReliabilityPrediction(updatedResult.reliabilityPrediction || 0);
      if (updatedResult.reliabilityPredictionTimestamp) {
        setUpdatedReliabilityPredictionTimestamp(
          updatedResult.reliabilityPredictionTimestamp
            ? format(
                new Date(updatedResult.reliabilityPredictionTimestamp),
                DATE_REL_FORMAT,
              )
            : '',
        );
      }
    }
  };

  return (
    <div className="bg-background-surface border-support-line border-b px-4 py-3">
      <div className="text-preset-6 text-ink-not-as-dark flex font-normal tracking-wide">
        <div className="mr-4 flex flex-1 items-center">
          <Can I="read" a="Retool" passThrough>
            {(allowed: boolean) =>
              allowed ? (
                <>
                  <a
                    href={`/bookings/${jobId}`}
                    className="text-ink-link uppercase"
                    title={jobName}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {jobName.slice(0, MAX_JOB_NAME_LENGTH)}
                    {jobName.length > MAX_JOB_NAME_LENGTH ? '...' : null}
                    <Info className="ml-1 inline align-text-bottom" />
                  </a>
                  <SpacedDot />
                </>
              ) : (
                <div className="flex">
                  <p className="text-ink-link uppercase" title={jobName}>
                    {jobName.slice(0, MAX_JOB_NAME_LENGTH)}
                    {jobName.length > MAX_JOB_NAME_LENGTH ? '...' : null}
                    <Info className="ml-1 inline align-text-bottom" />
                  </p>
                  <SpacedDot />
                </div>
              )
            }
          </Can>
          {city}, {state}, {regionName || 'No Region'}
          <SpacedDot />
          {formattedStartDate} - {formattedEndDate}
          <SpacedDot />
          <span title="Total hours minus the unpaid break time">
            {totalHours}h {remainingMinutes}m
          </span>
          {unpaidBreakMinutes ? (
            <>
              <SpacedDot />
              {unpaidBreakMinutes}m Unpaid break
            </>
          ) : null}
          <>
            <SpacedDot />
            Rel. Pred.{' '}
            {updatedReliabilityPrediction
              ? Math.round(updatedReliabilityPrediction * 10) / 10
              : 0}
            <button
              disabled={loading}
              type="button"
              className="m-0.5 mx-2 inline-flex h-6 w-6 items-center rounded-md border border-gray-600 p-0.5 font-normal focus:outline-none"
              onClick={handleUpdateReliabilityPrediction}
            >
              <Refresh
                className="fill-gray-600"
                height="18"
                loading={loading}
              />
            </button>
            <span title="UTC timezone">
              {updatedReliabilityPredictionTimestamp || ''}
            </span>
          </>
        </div>
        <div className="text-brand-tend flex items-center font-medium">
          {` ${moneyFormatter(hourlyPayout / 100)}/hr`}
          {' + '}
          {tipType === TipType.INCLUDE_TIP
            ? `${moneyFormatter((tipAmount || 0) / 100)}/hr`
            : tipLabel}
        </div>
      </div>
    </div>
  );
};

const staffingListFetchPolicy = {
  fetchPolicy: 'cache-and-network' as WatchQueryFetchPolicy,
  nextFetchPolicy: 'cache-first' as WatchQueryFetchPolicy,
};

const sortQueryKeyMap: Record<ColumnName, string | undefined> = {
  tender: 'name',
  preference: 'clientPreference',
  drivingDistance: 'drivingDistance',
  status: 'status',
  conflicts: 'scheduleConflicts',
  score: 'score',
  classif: 'workerClassification',
  tagMatch: 'positionTagsMatching',
};

const ClientRow: React.FC<{
  clientEmail: string;
  tags: (GetShifts_shifts_edges_node_job_client_tags | null)[];
}> = ({ clientEmail, tags }) => {
  return (
    <div className="bg-background-surface border-support-line flex border-b">
      <div className="text-preset-4 text-ink-dark flex flex-1 font-medium">
        <div className="flex-1 flex-col items-center px-4 py-3">
          {clientEmail && (
            <div className="text-ink-not-as-dark ml-2 mr-0">
              <SpacedDot />
              <span className="text-preset-6 text-ink-not-as-dark lowercase">
                {clientEmail}
              </span>
              <SpacedDot />

              {tags &&
                tags.map((tag) => {
                  return (
                    tag && (
                      <span
                        key={tag.id}
                        className={
                          'text-preset-6 bg-status-positive-light text-ink-primary m-1 inline-flex items-center whitespace-nowrap rounded-full px-3 py-1.5 font-medium lowercase'
                        }
                      >
                        {tag.name}
                      </span>
                    )
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ShiftListItem: React.FC<{ shift: GetShifts_shifts_edges_node }> = ({
  shift,
}) => {
  const approvedSelectedStaffings = useContext(
    ApprovedStaffingSelectionContext,
  );
  const selectedStaffings = useContext(StaffingSelectionContext);
  const flags = useFlags();
  const isFlexPoolShift = shift.job.isFlexpoolOrder === true;
  const isNowsta2 = shift.job.isNowsta2 === true;
  const isCancelled = shift.status === ShiftStatus.CANCELLED;
  const organizationName = shift.job.organization?.name || '';
  const clientEmail = shift.job.client?.email || '';
  const tags = shift.job.client?.tags || [];
  const country = shift.job.venue?.address?.country;

  const [cancelShiftRequest] = useCancelShiftRequestMutation();

  const commonStaffingListProps = useMemo(
    () => ({
      shiftId: shift.id,
      defaultSortColumn: 'tender' as const,
      defaultSortDirection: OrderByDirectionEnum.ASC,
    }),
    [shift.id],
  );

  const getQueryVariables = useCallback(
    (column?: ColumnName, direction?: OrderByDirectionEnum, page = 1) => ({
      shiftId: shift.id,
      options: {
        orderByField:
          sortQueryKeyMap[column ?? commonStaffingListProps.defaultSortColumn],
        orderByDirection:
          direction ?? commonStaffingListProps.defaultSortDirection,
      },
      pagination: {
        page,
        limit: STAFFINGS_DEFAULT_PAGE_SIZE,
      },
    }),
    [
      commonStaffingListProps.defaultSortColumn,
      commonStaffingListProps.defaultSortDirection,
      shift.id,
    ],
  );

  const [
    getApprovedJobApplications,
    {
      data: approvedJobApplications,
      loading: loadingApprovedJobApplications,
      previousData: previousApprovedJobApplications,
      subscribeToMore: subscribeToMoreApprovedJobApplications,
    },
  ] = useLazyApprovedJobApplicationsPaginatedQuery({
    variables: getQueryVariables(),
    ...staffingListFetchPolicy,
  });

  const [
    getPendingJobApplications,
    {
      data: pendingJobApplications,
      loading: loadingPendingJobApplications,
      previousData: previousPendingJobApplications,
      subscribeToMore: subscribeToMorePendingJobApplications,
    },
  ] = useLazyPendingJobApplicationsPaginatedQuery({
    variables: getQueryVariables(),
    ...staffingListFetchPolicy,
  });

  const [
    getRemovedJobApplications,
    {
      data: removedJobApplications,
      loading: loadingRemovedJobApplications,
      previousData: previousRemovedJobApplications,
      subscribeToMore: subscribeToMoreRemovedJobApplications,
    },
  ] = useLazyRemovedJobApplicationsPaginatedQuery({
    variables: getQueryVariables(),
    ...staffingListFetchPolicy,
  });

  const [
    getShiftRequests,
    {
      data: shiftRequests,
      loading: loadingShiftRequests,
      previousData: previousShiftRequests,
    },
  ] = useLazyShiftRequestsQuery({
    variables: {
      pagination: {
        page: 1,
        limit: STAFFINGS_DEFAULT_PAGE_SIZE,
      },
      filters: {
        shiftId: shift.id,
      },
    },
    fetchPolicy: 'cache-and-network' as WatchQueryFetchPolicy,
    nextFetchPolicy: 'cache-first' as WatchQueryFetchPolicy,
  });

  const shiftRequestsCount = shiftRequests?.shiftRequests?.items?.length || 0;
  const removedApplicationsCount =
    removedJobApplications?.removedJobApplicationsPaginated.items.length || 0;

  const handleExpandApproved = useCallback(
    (isExpanded: boolean) => {
      let unsubscribe: (() => void) | undefined;
      if (!flags.disableGraphqlSubscriptions) {
        unsubscribe = subscribeToMoreApprovedJobApplications(
          subscribeToMoreJobApplicationsOptions(
            shift.id,
            'approvedJobApplicationsPaginated',
          ),
        );
      }

      if (!isExpanded) {
        getApprovedJobApplications();
      } else if (unsubscribe) {
        unsubscribe();
      }
    },
    [
      getApprovedJobApplications,
      shift.id,
      subscribeToMoreApprovedJobApplications,
      flags.disableGraphqlSubscriptions,
    ],
  );

  const handleExpandPending = useCallback(
    (isExpanded: boolean) => {
      let unsubscribe: (() => void) | undefined;
      if (!flags.disableGraphqlSubscriptions) {
        unsubscribe = subscribeToMorePendingJobApplications(
          subscribeToMoreJobApplicationsOptions(
            shift.id,
            'pendingJobApplicationsPaginated',
          ),
        );
      }

      if (!isExpanded) {
        getPendingJobApplications();
      } else if (unsubscribe) {
        unsubscribe();
      }
    },
    [
      getPendingJobApplications,
      shift.id,
      subscribeToMorePendingJobApplications,
      flags.disableGraphqlSubscriptions,
    ],
  );

  const handleExpandRemoved = useCallback(
    (isExpanded: boolean) => {
      let unsubscribe: (() => void) | undefined;
      if (!flags.disableGraphqlSubscriptions) {
        unsubscribe = subscribeToMoreRemovedJobApplications(
          subscribeToMoreJobApplicationsOptions(
            shift.id,
            'removedJobApplicationsPaginated',
          ),
        );
      }

      if (!isExpanded) {
        getRemovedJobApplications();
      } else if (unsubscribe) {
        unsubscribe();
      }
    },
    [
      getRemovedJobApplications,
      shift.id,
      subscribeToMoreRemovedJobApplications,
      flags.disableGraphqlSubscriptions,
    ],
  );

  useEffect(() => {
    // NOTE: we need to fetch the data here because the ShiftRequests
    // subscription is not yet implemented.
    getShiftRequests();
    getApprovedJobApplications();
  }, [getShiftRequests, getApprovedJobApplications]);

  const handleApprovedOptionsChange = useCallback(
    (options: Partial<ListOptions>) =>
      getApprovedJobApplications({
        variables: getQueryVariables(
          options.sortColumn,
          options.sortDirection,
          options.page,
        ),
      }),
    [getApprovedJobApplications, getQueryVariables],
  );

  const handleApprovedSort = useCallback(
    (sortColumn?: ColumnName, sortDirection?: OrderByDirectionEnum) =>
      handleApprovedOptionsChange({
        sortColumn,
        sortDirection,
      }),
    [handleApprovedOptionsChange],
  );

  const handleApprovedPagination = useCallback(
    (
      page: number,
      sortColumn?: ColumnName,
      sortDirection?: OrderByDirectionEnum,
    ) => handleApprovedOptionsChange({ page, sortColumn, sortDirection }),
    [handleApprovedOptionsChange],
  );

  const handlePendingOptionsChange = useCallback(
    (options: Partial<ListOptions>) =>
      getPendingJobApplications({
        variables: getQueryVariables(
          options.sortColumn,
          options.sortDirection,
          options.page,
        ),
      }),
    [getPendingJobApplications, getQueryVariables],
  );

  const handlePendingSort = useCallback(
    (sortColumn?: ColumnName, sortDirection?: OrderByDirectionEnum) =>
      handlePendingOptionsChange({
        sortColumn,
        sortDirection,
      }),
    [handlePendingOptionsChange],
  );

  const handlePendingPagination = useCallback(
    (
      page: number,
      sortColumn?: ColumnName,
      sortDirection?: OrderByDirectionEnum,
    ) => handlePendingOptionsChange({ page, sortColumn, sortDirection }),
    [handlePendingOptionsChange],
  );

  const handleRemovedOptionsChange = useCallback(
    (options: Partial<ListOptions>) =>
      getRemovedJobApplications({
        variables: getQueryVariables(
          options.sortColumn,
          options.sortDirection,
          options.page,
        ),
      }),
    [getRemovedJobApplications, getQueryVariables],
  );

  const handleRemovedSort = useCallback(
    (sortColumn?: ColumnName, sortDirection?: OrderByDirectionEnum) =>
      handleRemovedOptionsChange({
        sortColumn,
        sortDirection,
      }),
    [handleRemovedOptionsChange],
  );

  const handleRemovedPagination = useCallback(
    (
      page: number,
      sortColumn?: ColumnName,
      sortDirection?: OrderByDirectionEnum,
    ) => handleRemovedOptionsChange({ page, sortColumn, sortDirection }),
    [handleRemovedOptionsChange],
  );

  const handleShiftRequestsOptionsChange = useCallback(
    (options: Partial<ListOptions>) =>
      getShiftRequests({
        variables: getQueryVariables(
          options.sortColumn,
          options.sortDirection,
          options.page,
        ),
      }),
    [getShiftRequests, getQueryVariables],
  );

  const handleShiftRequestsSort = useCallback(
    (sortColumn?: ColumnName, sortDirection?: OrderByDirectionEnum) =>
      handleShiftRequestsOptionsChange({
        sortColumn,
        sortDirection,
      }),
    [handleShiftRequestsOptionsChange],
  );

  const handleShiftRequestsPagination = useCallback(
    (
      page: number,
      sortColumn?: ColumnName,
      sortDirection?: OrderByDirectionEnum,
    ) => handleShiftRequestsOptionsChange({ page, sortColumn, sortDirection }),
    [handleShiftRequestsOptionsChange],
  );

  const renderData = {
    testIds: [
      ...(isFlexPoolShift
        ? ['shift-card-type-flexpool']
        : ['shift-card-type-tend']),
      ...(isCancelled && flags.canceledOrdersFilter
        ? ['shift-card-status-cancelled']
        : []),
    ],
    backgroundClasses:
      isCancelled && flags.canceledOrdersFilter
        ? 'bg-status-destructive-faint'
        : isFlexPoolShift
        ? 'bg-background-nowsta'
        : 'bg-background-tend',
  };

  const [isClientExpanded, setIsClientExpanded] = useState(true);

  const requestedTenderIds = shiftRequests?.shiftRequests.items.map(
    (shiftRequest) => shiftRequest.tender.id,
  );

  const handleClientToggle = () => {
    setIsClientExpanded(!isClientExpanded);
  };

  const handleCancelShiftRequest = useCallback(
    async (shiftRequestId: string) => {
      await cancelShiftRequest({
        variables: {
          id: shiftRequestId,
        },
        refetchQueries: [
          {
            query: SHIFT_REQUESTS,
            variables: {
              pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
              filters: {
                shiftId: shift.id,
              },
            },
          },
        ],
      });
    },
    [cancelShiftRequest, shift.id],
  );

  return (
    <div
      className="-my-2 mb-10 overflow-x-auto last:mb-1 sm:-mx-6 lg:-mx-8"
      data-cy={renderData.testIds.join(':')}
      data-testid={'shift-card'}
      id={`shift-${shift.id}`}
    >
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div className="border-support-line focus-within:outline-primary/30 overflow-hidden rounded-t-lg border border-b focus-within:outline focus-within:outline-[3px]">
          <PositionRow
            shift={shift}
            isFlexpoolShift={isFlexPoolShift}
            isCancelled={isCancelled}
            organizationName={organizationName}
            onToggle={handleClientToggle}
            isExpanded={isClientExpanded}
            isNowsta2={isNowsta2}
          />

          {isClientExpanded && (
            <ClientRow clientEmail={clientEmail} tags={tags} />
          )}

          <JobRow
            shiftId={shift.id}
            jobName={shift.job.name}
            jobId={shift.job.id}
            startDate={shift.startDateTime}
            endDate={shift.endDateTime}
            venue={shift.job.venue}
            totalShiftHours={shift.expectedWorkedHours}
            hourlyPayout={shift.tenderHourlyPayoutWithoutTip}
            tipType={shift.tip?.type}
            tipAmount={shift.tip?.amount}
            tipLabel={shift.tip?.label}
            reliabilityPrediction={
              shift.reliabilityPrediction?.reliabilityPrediction
            }
            reliabilityPredictionTimestamp={
              shift.reliabilityPrediction?.reliabilityPredictionTimestamp
            }
          />

          <StaffingList
            title="APPROVED"
            count={shift.staffedSlots}
            maxCount={shift.quantity}
            staffings={
              approvedJobApplications?.approvedJobApplicationsPaginated.items ||
              previousApprovedJobApplications?.approvedJobApplicationsPaginated
                .items
            }
            loading={loadingApprovedJobApplications}
            emptyStateLabel="There are no approved Tenders yet"
            onToggle={handleExpandApproved}
            onSelectRow={(staffing) => {
              approvedSelectedStaffings?.dispatch({
                type: 'add',
                id: staffing.id,
                shiftId: shift.id,
              });
            }}
            onDeselectRow={(staffing) => {
              approvedSelectedStaffings?.dispatch({
                type: 'remove',
                id: staffing.id,
                shiftId: shift.id,
              });
            }}
            onSort={handleApprovedSort}
            warning={shift.staffedSlots < shift.quantity}
            backgroundClass={renderData.backgroundClasses}
            actionComponent={
              <Can I="create" a="Staffing">
                <div className="flex gap-1">
                  {flags.requestWorkers && (
                    <RequestTenderPopover
                      shiftId={shift.id}
                      country={country}
                    />
                  )}

                  <ApplyForTenderPopover
                    shiftId={shift.id}
                    positionId={shift.position.id}
                    country={country}
                  />
                </div>
              </Can>
            }
            paginationMeta={
              approvedJobApplications?.approvedJobApplicationsPaginated.meta ||
              previousApprovedJobApplications?.approvedJobApplicationsPaginated
                .meta
            }
            onPageChange={handleApprovedPagination}
            positionTagGroups={shift.position.tagGroups}
            requestedTenderIds={requestedTenderIds}
            {...commonStaffingListProps}
          />

          <StaffingList
            title="APPLICANTS"
            count={shift.applicantsQuantity}
            staffings={
              pendingJobApplications?.pendingJobApplicationsPaginated.items ||
              previousPendingJobApplications?.pendingJobApplicationsPaginated
                .items
            }
            emptyStateLabel="There are no applicants yet"
            loading={loadingPendingJobApplications}
            onToggle={handleExpandPending}
            onSelectRow={(staffing) => {
              selectedStaffings?.dispatch({
                type: 'add',
                id: staffing.id,
                shiftId: shift.id,
              });
            }}
            onDeselectRow={(staffing) => {
              selectedStaffings?.dispatch({
                type: 'remove',
                id: staffing.id,
                shiftId: shift.id,
              });
            }}
            onAction={(staffing) => {
              selectedStaffings?.dispatch({
                type: 'remove',
                id: staffing.id,
                shiftId: shift.id,
              });
            }}
            onSort={handlePendingSort}
            paginationMeta={
              pendingJobApplications?.pendingJobApplicationsPaginated.meta ||
              previousPendingJobApplications?.pendingJobApplicationsPaginated
                .meta
            }
            onPageChange={handlePendingPagination}
            positionTagGroups={shift.position.tagGroups}
            requestedTenderIds={requestedTenderIds}
            {...commonStaffingListProps}
          />

          {Boolean(removedApplicationsCount || shift.removedQuantity) && (
            <StaffingList
              title="NOT ATTENDING"
              count={shift.removedQuantity}
              staffings={
                removedJobApplications?.removedJobApplicationsPaginated.items ||
                previousRemovedJobApplications?.removedJobApplicationsPaginated
                  .items
              }
              loading={loadingRemovedJobApplications}
              onToggle={handleExpandRemoved}
              onSort={handleRemovedSort}
              paginationMeta={
                removedJobApplications?.removedJobApplicationsPaginated.meta ||
                previousRemovedJobApplications?.removedJobApplicationsPaginated
                  .meta
              }
              onPageChange={handleRemovedPagination}
              positionTagGroups={shift.position.tagGroups}
              {...commonStaffingListProps}
            />
          )}

          {Boolean(shiftRequestsCount) && flags.requestWorkers && (
            <RequestsList
              title="REQUESTED"
              count={shiftRequestsCount}
              requests={
                shiftRequests?.shiftRequests.items ||
                previousShiftRequests?.shiftRequests.items
              }
              emptyStateLabel="There are no requests yet"
              loading={loadingShiftRequests}
              onToggle={(isExpanded) => {
                !isExpanded && getShiftRequests();
              }}
              onCancelShiftRequest={handleCancelShiftRequest}
              onSort={handleShiftRequestsSort}
              paginationMeta={
                shiftRequests?.shiftRequests.meta ||
                previousShiftRequests?.shiftRequests.meta
              }
              onPageChange={handleShiftRequestsPagination}
              positionTagGroups={shift.position.tagGroups}
              {...commonStaffingListProps}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShiftListItem;
